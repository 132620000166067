.paramDropzone {
  width: 100%;
  height: 160px;
  border: 2px dashed var(--bg-disabled);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-main);
  font-size: 18px;
}
