.pp_select_schema {
  color: var(--text-main);
}
.pp_schema_1_item_row, .pp_schema_2_item_row{
  color: var(--text-main);
  text-align: center;
  width: 40%;
  word-wrap: break-word
}
.pp_schema_3_item_row{
  color: var(--text-main);
}
.pp_schema_2_item_row, .pp_schema_3_item_row {
  margin-left: 20px;
}

.selectDestinity {
  width: 100%;
  text-align: center;
  height: 36px;
}
.selectDestinity .css-1s2u09g-control,
.selectDestinity .css-1pahdxg-control {
  background-color: var(--bg-secondary);
}

.selectDestinity .css-1gtu0rj-indicatorContainer {
  color: white;
}

.selectDestinity > .css-qc6sy-singleValue {
  color: white !important;
  font-weight: 700;
}
.css-26l3qy-menu,
.css-26l3qy-menu div {
  color: hsl(0, 0%, 20%);
  background-color: white;
  font-size: 15px;
}

.css-26l3qy-menu div:hover {
  background-color: hsl(180, 15%, 87%);
}