.es_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.es_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 10px 0;
  font-size: 2rem;
  font-weight: bold;
  color: #169ad2;
}

.es_title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--text-main);
}

/* .container_icon_table {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.icon_table {
  padding: 0 0.2rem;
}

.icon_table:hover {
  cursor: pointer;
} */

.dm_checkIcon {
  width: 100%;
  display: flex;
  justify-content: center;
}

.es_header_column {
  background-color: var(--table-header);
  border: 1px solid var(--table-header);
  color: white;
  height: 40px;
}

/* .MuiDataGrid-columnHeaderTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center; 
} */

.MuiDataGrid-sortIcon {
  fill: white;
  stroke: white;
}

.es_column_title {
  color: white;
  text-align: center;
}

.es_whiteRow {
  background-color: white;
}

.es_whiteRow:hover {
  background-color: var(--text-info);
}

.es_colorRow {
  background-color: #e9e5e1;
}

.es_colorRow:hover {
  background-color: var(--text-info);
}

.MuiDataGrid-panel {
  background-color: white;
}

.es_button_modify:active {
  transform: scale(0.95);
  transition: 0.3s all;
}

.es_form_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40vw;
}

.es_selects_container {
  display: flex;
  align-items: space-between;
  width: 100%;
  height: 100%;
}

.es_form_input {
  font-size: 13px;
  width: 100%
}

.es_modify_column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 45%;
  margin-right: 2.5%;
  min-width: 225px;
}

.es_buttons_column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.es_modify_title {
  font-size: 16px;
  color: var(--text-main);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.es_add_button {
  background: var(--bg-secondary);
  border-radius: 50%;
  border: 1px solid var(--bg-secondarye);
  color: white;
  font-size: 30px;
  height: 38px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s;
  vertical-align: middle;
  width: 38px;
}

.es_add_button:hover {
  background: white;
  cursor: pointer;
  font-weight: bold;
  color: var(--text-secondary);
}

.es_add_button:active {
  transform: scale(0.95);
  transition: 0.3s all;
}

.es_modify_description {
  font-size: 13px;
  color: var(--text-main);
  margin: 5px 0;
}

.es_modify_description_icons {
  padding: 0;
  margin: 0;
  text-align: center;
}

.es_newValueError {
  color: red;
  font-size: 14px;
  margin: 3px 0;
}

.MuiDataGrid-panelHeader,
.MuiDataGrid-columnsPanel,
.MuiDataGrid-panelFooter,
.MuiDataGrid-panelHeader div.MuiFormControl-root label,
.MuiDataGrid-panelFooter button,
.MuiDataGrid-filterForm {
  background-color: white;
  color: var(--text-main);
}

.MuiDataGrid-panelFooter button:hover {
  color: var(--text-error);
}

.MuiList-root,
.MuiList-root svg {
  color: white;
}

.es_guardar_vista_box {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 2px 5px 2px 15px;
}

.es_guardar_vista_box:hover{
  color: #f90d4a;
  cursor: pointer;
}

.es_guardar_vista {
  align-items: center;
  background: transparent;
  color: var(--button-cancel);
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
  margin: 2px 0 0 0;
  width: 130px;
  padding: 5px 4px;
  vertical-align: center;
  text-transform: uppercase;
}

.es_guardar_vista:hover {
  color: #f90d4a;
  cursor: pointer;
}

.es_info_rowsToTransform {
  font-weight: 700;
  margin-top: 10px;
  font-size: 16px;
  color: var(--text-main);
}

/*Estilos para pisar la letra blanca de los popup*/
.MuiList-root.MuiList-padding.MuiDataGrid-menuList{
  color: var(--text-info) !important
}

.MuiList-root.MuiList-padding.MuiDataGrid-menuList svg{
  color: var(--text-info) !important
}