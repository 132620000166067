.cc_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; 
  min-width: 75vw
}

.cc_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  font-size: 2rem;
  font-weight: bold;
}

.cc_title {
  font-size: 2rem;
  font-weight: bold;
  color: "var(--text-main)";
  margin-top: 35px;
}

.cc_step_title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-subtitle);
  padding: 25px 0px 5px 0px;
}

.cc_steps_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.cc_step4_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.cc_flex_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cc_arrow_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cc_arrow_container:hover {
  cursor: pointer;  
}

.cc_arrow_container svg:hover{
  color: var(--text-error);
}

.cc_arrow_container svg path:hover{
  fill: var(--text-error);
}
.rowWithoutPadding{
  padding: 0 !important
}
