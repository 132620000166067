.dm_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
}

.dm_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  font-size: 2rem;
  font-weight: bold; 
}

.switch_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switch > div > .MuiFormControlLabel-root{
 margin: 0;
}

.dm_title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--text-main);
}

.dm_title_preview {
    font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-main);
}

.dm_button_runValidations, .dm_button_upload { 
   align-items: center;
  appearance: none;
  border-radius: 30px;
  border: none;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  font-size: 14px;
  font-weight: 100;
  margin: 10px 10px;
  padding: 15px 5px;
  text-transform: uppercase;
  transition: all 0.4s;
  width: 190px;
  height: 45px;
  justify-content: center;
}

.dm_button_runValidations {
  background-color: var(--text-main);
}

.dm_button_upload {
  background-color: #F90D4A;
}

.dm_button_runValidations:hover, .dm_button_upload:hover {  
  cursor: pointer;
}

.dm_button_runValidations:hover{
  background-color: white;
  border: 2px solid var(--text-main);
  color: var(--text-main);
}

.dm_button_upload:hover{
  background-color: white;
  border: 2px solid #F90D4A;
  color: var(--text-main);
}

.dm_button_runValidations:active, .dm_button_upload:active {
  transform: scale(0.95);
  transition: 0.3s all;
}

.preview_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.dm_preview_title_container {
  display: flex;
  width: 95%;
}

.dm_input_uploadFiles {
    background-color: white;
    padding: 3px;
    border-radius: 4px;
    margin: 10px 0;
    width: 100%;
}

.dm_checkIcon {
  width: 100%;
  display: flex;
  justify-content: center;
}

.dm_header_column {
  background-color: var(--bg-secondary);
  height: 40px;
}

.dm_header_column2 {
  background-color: var(--bg-secondary);
  height: 35px !important;
}

.dm_column_title {
  color: white;
}

.dm_column_title_route_log {
  line-height: 1.5;
  color: white;
  height: 100%;
  white-space: normal; 
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}

.dm_messages_uploadFiles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  margin: 5px 0;
  width: 580px;
}

.dm_messages_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0px;
}

.dm_dropzone_container {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
}

.dropzone {
  width: 500px;
  height: 160px;
  border: 2px dashed var(--bg-info);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-main);
  font-size: 18px;
}

.dropzone1 {
  width: 100%;
  height: 160px;
  border: 2px dashed var(--bg-info);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-main);
  font-size: 18px;
}

.dropzone:hover {
  cursor: pointer;
}

.dropzone1:hover {
  cursor: pointer;
}

.dm_title_filesToUpload {
  margin-bottom: 0px;
}

.dm_list_filesToUpload {
  margin: 3px 0;
}