
.css-1pkd666 .SnackbarItem-variantSuccess {
  background-color: var(--bg-success);
}

.css-1pkd666 .SnackbarItem-variantError {
  background-color: var(--bg-error);
}

.css-1pkd666 .SnackbarItem-variantWarning {
  background-color: var(--bg-warning);
  color: var(--text-main);
  font-weight: 700;
}

.css-1pkd666 .SnackbarItem-variantInfo {
  background-color: var(--bg-info);
}

.MuiDataGrid-cell--withRenderer {
  padding: 0 !important;
}

.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart.css-1gi6etn-MuiFormControlLabel-root {
  margin-right: 0 !important;
}

.tooltipContainerStyles {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooltipLabel {
  margin-bottom: 8px;
}

.buttonTooltip {
  border-radius: 5px;
  background-color: var(--chart-22);
  border: none;
  height: 30px;
  color: var(--greeny);
  font-weight: 600;
  cursor: pointer;
  width: 95%;
}

.mp_column_select svg {
  color: #24394b;
}

