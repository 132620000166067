.login-container {
  align-items: center;
  background-color: var(--bg-main);
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
}

.login_form {
  align-items: center;
  background: white;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  max-width: 50%;
  min-width: 350px;

}

.login_form_box {
  align-items: center;
  color: black;
  display: flex;
  flex-direction: column;
  min-height: 350px;
}

.login_title {
  border-bottom: 1px solid var(--text-main);
  color: var(--text-main);
  font-weight: 100;
  text-align: center;
}

.login_form_input {
  -webkit-appearance: none;
  border-radius: 4px;
  border: 1px solid grey;
  box-sizing: border-box;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 10px 15px;
  width: 100%;
}

.login_form:disabled {
  opacity: 0.4;
}

.login_buttons_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login_button_confirm,
.login_button_requestUser {
  align-items: center;
  border-radius: 30px;
  border: none;
  color: white;
  display: flex;
  font-size: 14px;
  font-weight: 100;
  height: 45px;
  justify-content: center;
  letter-spacing: 2px;
  margin: 10px 10px;
  padding: 15px;
  text-transform: uppercase;
  transition: all 0.4s;
  width: 150px;
}

.login_button_confirm {
  background: var(--bg-main);
}

.login_button_requestUser {
  background: var(--button-cancel);
}

.login_button_confirm:hover,
.login_button_requestUser:hover {
  background: white;
  cursor: pointer;
  font-weight: bold; 
}

.login_button_confirm:hover {
  border: 2px solid var(--bg-main);
  color: var(--text-main);
}

.login_button_requestUser:hover {
  border: 2px solid var(--button-cancel);
  color: var(--button-cancel);
}

.login_button_confirm:active,
.login_button_requestUser:active {
  transform: scale(0.95);
  transition: 0.3s all;
}

.App {
  margin: 0 auto;
  max-width: 600px;
}

.login_logo-container {
  background-color: white;
  padding: 10px 10px;
}

.login_logo {
  width: 100px;
}

.login_password_container {
  position: relative;
}

.login_password_icon {
  bottom: 7px;
  position: absolute;
  right: 10px;
  top: 7px;
}

.login_password_icon:hover {
  cursor: pointer;
}

.login_password_icon:active {
  cursor: pointer;
}

.rememberMeContainer,
.forgotPasswordContainer,
.trustContainer {
  color: black;
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  align-items: center;
}

.rememberMeLabel,
.trustLabel {
  color: black;
  margin-left: 10px;
}

.errmsg {
  color: red;
}

.offscreen {
  display: none;
}

.checksContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.checksContainerError{
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.checksContainer > div > div > input {
  margin: 0;
  padding: 0;
}

.video_background_styles {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}
