.stages_label {
  color: var(--text-main);
  font-size: 18px;
  font-weight: bold;
  margin-right: 7px;
}

.stages_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkBoxMiniSelect .MuiList-root {
  color: var(--text-main) !important;
}

/*GRÁFICO*/
.highcharts-drilldown-axis-label {
  fill: var(--text-info) !important;
  text-decoration: none !important;
  color: var(--text-info) !important;
  font-size: 13px !important;
}

/*BUCKETS LIST*/

.bucket-item-text span {
  font-size: 15px;
  text-align: center;
}

.bucket-item {
  border-bottom: 2px solid var(--bg-disabled);
}
.bucket-item-open {
  border-bottom: 2px solid transparent !important;
}

.Mui-selected.bucket-item,
.Mui-selected.bucket-item + .etapa-selector {
  background-color: rgba(19, 79, 158, 0.4);
}

.Mui-selected.bucket-item span {
  color: var(--text-info);
  font-weight: 700;
}

.bucket-item {
  cursor: pointer;
}
.bucket-item svg {
  color: var(--text-info);
}

/*--Selector de etapa--*/
.etapa-selector ul {
  border-bottom: 2px solid var(--text-disabled);
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 5px;
}

.etapa-selector span {
  font-size: 15px;
  font-weight: 700;
  color: var(--text-info);
}

.selectedStage-selector {
  background-color: var(--bg-main);
  color: white;
}

.nonSelectedStage-selector {
  background-color: white;
}

/* SCROLL BAR */

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(100, 100, 100);
  background-color: rgb(209, 196, 196);
  /*border-radius: 10px;*/

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bg-neutral);
  border-radius: 5px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--bg-neutral-hover);

}